// core
import { mapGetters } from 'vuex';
import Settings from '@/config/Settings'

// components
import Layout from '@/Views/_Layout/index.vue'
import HelpMenu from '@/Views/_Components/HelpMenu/index.vue';
import InterseptedImage from '@/Views/_Components/Helpers/InterseptedImage/index.vue';

// helpers
import { slugify } from "@/Helpers/Misc";

// icons
import { XIcon } from 'vue-feather-icons';

export default {
  name: 'Books',

  data() {
    return {
      tab: 'reading'
    }
  },

	components: {
    // components
    'oston-layout': Layout,
    'oston-help-menu': HelpMenu,
		'oston-intersepted-image': InterseptedImage,

		// icons
		'feather-icon-close': XIcon
  },

  mounted() {
    Settings.title('Meus Livros')
  },

  methods: {
    slugify,

    select: function(type) {
      _.controller('book').type(type)
    }
  },

  computed: mapGetters({
    books: 'MagazineModel/magazines',
    type: 'BookModel/type'
  })
};
